<template>
  <div :class="$style.list">
    <div :class="$style.wrapper"></div>
    <el-table :data="feedbacks" stripe>
      <el-table-column prop="name" label="Название" />

      <el-table-column label="Создано" width="80px">
        <template slot-scope="scope">
          {{ formatDate(scope.row.createdAt) }}
        </template>
      </el-table-column>

      <el-table-column prop="clientName" label="Клиент" />
      <el-table-column prop="companyName" label="Компания" />
      <el-table-column prop="phone" label="Телефон" />
      <el-table-column prop="email" label="Почта" />
    </el-table>
    <el-pagination
      v-if="total / limit > 1"
      :class="$style.pagination"
      layout="prev, pager, next"
      :page-size="limit"
      :total="total"
      :current-page.sync="page"
      @current-change="getList"
      background
    >
    </el-pagination>
  </div>
</template>

<script>
import delivery from '@/delivery'
import { formatDate } from '@/helpers/index.js'

export default {
  data() {
    return {
      feedbacks: [],
      page: 1,
      limit: 20,
      total: 0
    }
  },
  created() {
    this.getList()
  },
  methods: {
    formatDate(date) {
      return formatDate(date)
    },
    async getList() {
      const loading = this.$loading({
        lock: true
      })

      const { value, error } =
        await delivery.NotificationServiceCore.FeedbacksActions.getList({
          page: this.page,
          limit: this.limit,
          source: this.$configData.sellers_link
        })
      loading.close()
      if (error) return
      this.feedbacks = value.data
      this.total = value.meta.count
    }
  }
}
</script>

<style lang="scss" module>
.list {
  padding: 1rem;
  .pagination {
    @include pagination;
  }
}
</style>
